.navbarStyle {
    background-color: white;
    color: black;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.bold {
    font-weight: 700;
}

.leading-35px {
    line-height: 35px;
}

.weight-500{
    font-weight: 500;
}

.p-y-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.p-x-0 {
    padding-left: 0;
    padding-right: 0;
}

.textstyle-center {
    text-align: center;
}

.justify-center {
    justify-content: center;
}

.text-32px {
    font-size: 32px;
}

.weight-600 {
    font-weight: 600;
}

.object-bottom {
    object-position: top;
}

.object-cover {
    object-fit: cover;
}

.weight-700 {
    font-weight: 700;
}

.mt-0-5rem {
    margin-top: 0.5rem;
}

.m-0 {
    margin: 0;
}

.mb-1-5rem {
    margin-bottom: 1.5rem;
}

.h-11rem {
    height: 11rem;
}


.topBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.h-280px {
    height: 280px;
}

.h-10rem {
    height: 10rem;
}

.mt-2-5rem {
    margin-top: 2.5rem;
}

.displayHeader {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    font-family: 'Lora'
}

.display3 {
    font-size: 24px;
    line-height: 29px;
}

.display3 {
    font-weight: 500;
    font-family: 'Lora'
}

.display4 {
    font-size: 18px;
    line-height: 22px;
}

.display3, .display4 {
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0;
    font-family: 'Lora';
}

.flex-1 {
    flex: 1 1 0%;
}

.gap-2 {
    gap: 2rem;
}

.gap-1-5 {
    gap: 1.5rem;
}

.padding-1-5 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.v-pad-2rem {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.serif {
    font-family: "Vollkorn"
}

.poppins {
    font-family: 'Poppins'
}

.h-pad {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.h-pad-7-5 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.v-pad {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}

.v-pad-0-25rem {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}


.v-pad-8-7-5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.subtitle {
    font-style: normal;
    font-weight: 500;
    letter-spacing: -.01em;
    font-size: 24px;
    line-height: 17px;
}

.justify-between {
    justify-content: space-between;
}

.block {
    display: block;
}

.padding-75 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.border-stroke {
    border-color: black;
}

.sm-border-t {
    border-top-width: 1px;
}


.border-b {
    border-bottom-width: 1px;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-x-clip {
    overflow-x: clip;
}

.overflowstyle-hidden {
    overflow: hidden;
}


.v-padding-11px {
    padding-top: 11px;
    padding-bottom: 11px;
}

.border-none {
    border-style: none;
}

.border-l {
    border-left-width: 1px;
}

.outline-2 {
    outline-width: 2px;
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.paragraph2,
.paragraph3 {
    font-style: normal;
    font-weight: 400;
    letter-spacing: -.015em;
}

.fixed {
    position: fixed;
}

.left-0 {
    left: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

.z-40 {
    z-index: 40;
}

.z-10 {
    z-index: 10;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.border-0 {
    border-width: 0;
}

.border-width-1 {
    border-width: 1px;
}

.border-b {
    border-bottom-width: 1px;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.items-stretch {
    align-items: stretch;
}

.rounded-md {
    border-radius: 0.375rem;
}

.rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.text2 {
    font-size: 15px;
    line-height: 18px;
}

.max-w-500 {
    max-width: 500px;
}

.border-solid {
    border-style: solid;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.bg-black {
    background-color: black;
}

.bottomShadow {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.justify-end {
    justify-content: flex-end;
}


@media (min-width: 768px) {
    .md-pb-60 {
        padding-bottom: 60px;
    }
}

@media (min-width: 768px) {
    .md-w-433px {
        width: 520px;
    }
}

.w-auto {
    width: auto;
}

.pt-2rem {
    padding-top: 2rem;
}

.pt-1rem {
    padding-top: 1rem;
}

.pt-0-5rem {
    padding-top: 0.5rem;
}

.px-2rem {
    padding-left: 2rem;
    padding-right: 2rem;
}

.pt-0 {
    padding-top: 0;
}

.pb-2rem {
    padding-bottom: 2rem;
}

@media (min-width: 768px) {
    .md-pt-100 {
        padding-top: 100px;
    }
}

.pb-1-5rem {
    padding-bottom: 1.5rem;
}

.padded-container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

@media (min-width: 768px) {
    .padded-container {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
}


.padded-container,
.unpadded-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1568px;
}


.flex-col {
    flex-direction: column;
}

@media (min-width: 768px) {
    .md-flex-row {
        flex-direction: row;
    }
}

@media (min-width: 768px) {
    .md-mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
}

.max-w-700 {
    max-width: 800px;
}

.hidden {
    display: none;
}

@media (min-width: 768px) {
    .md-v-pad-60px {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.flex-col-reverse {
    flex-direction: column-reverse;
}

.sm-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

@media (min-width: 768px) {
    .md-grid {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
}

@media (min-width: 1024px) {
    .lg-grid {
        grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
    }
}


.gap-0-5rem {
    gap: 0.5rem;
}

.mt-1-5rem {
    margin-top: 1.5rem;
}

.grid {
    display: grid;
}

.gap-1-rem {
    gap: 1rem;
}

.display2 {
    font-size: 32px;
    line-height: 38px;
}

.display1, .display2 {
    font-style: normal;
    font-weight: 500;
    /* letter-spacing: 0; */
    font-family: "Lora";
}

.paragraph2, .paragraph3 {
    font-style: normal;
    font-weight: 400;
    /* letter-spacing: -.015em; */
    /* font-family: var(--whopkit-font-sans),sans-serif; */
}

.paragraph2 {
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 768px) {
    .md-gap-1-25rem {
        gap: 1.25rem;
    }
}

.pad-1-5rem {
    padding: 1.5rem;
}

@media (min-width: 768px) {
    .md-pad-2rem {
        padding: 2rem;
    }
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}


.no-decoration {
    text-decoration: none;
}

.textstyle-white{
    color: white;
}


.divider-line {
    border-color: #8754F2;
    border-style: solid;
    border-width: 1px;
    border-width: 1px;
    width: 2.5rem;
}

.rounded-xl {
    border-radius: 0.75rem;
}



.dynamic-hero {
    width: 100%;
}

@media (min-width: 640px) {
    .sm-max-w-509px {
        max-width: 509px;
    }

    .sm-display2,
    .display3 {
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0;
        font-family: 'Lora'
    }

    .sm-display-block {
        display: block;
    }

    .sm-display2, .sm-display3 {
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0;
        font-family: 'Lora';
    }

    .sm-display3 {
        font-size: 24px;
        line-height: 29px;
    }

    .sm-block{
        display: block;
    }

    .sm-max-w-600px{
        max-width: 600px;
    }
}


@media screen and (min-width: 768px) {

    .md-max-w-509px {
        max-width: 509px;
    }

    .md-leading-38px {
        line-height: 38px;
    }

    .md-text-32px {
        font-size: 32px;
    }

    .dynamic-hero {
        width: 600px;
    }

    .md-leading-62px {
        line-height: 62px;
    }

    .md-text-left {
        text-align: left;
    }

    .md-mb-0-5rem {
        margin-bottom: 0.5rem;
    }

    .md-self-start {
        align-self: flex-start;
    }

    .md-py-120px {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .md-text-56px {
        font-size: 56px;
    }

    .md-p-2-5rem {
        padding: 2.5rem;
    }

    .md-rounded-xl {
        border-radius: 0.75rem;
    }

    .md-border {
        border-width: 1px;
    }

    .md-p-4rem {
        padding: 4rem;
    }

    .md-hidden {
        display: none;
    }

    .md-p-0 {
        padding: 0;
    }

}

@media (min-width: 1024px) {

    .lg-block {
        display: block;
    }

    .lg-flex-row {
        flex-direction: row;
    }

    .lg-h-auto {
        height: auto;
    }

    .lg-overflow-visible {
        overflow: visible;
    }
 
    .lg-max-w-420px {
        max-width: 420px;
    } 

    .lg-w-600px {
        width: 600px;
    }

    .lg-max-w-lg {
        max-width: 32rem;
    }

    .lg-self-center {
        align-self: center;
    }
}

@media screen and (max-width: 768px){
    .sm-hidden-image{
        display: none
    }
}

@media (min-width: 1280px){
    .xl-block {
        display: block;
    }
}

