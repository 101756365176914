body {
    overscroll-behavior-y: none;
    overflow-x: hidden;
}

.main {
    /* overflow-y: hidden; */
    width: 100%;
    background-color: white;
    background-size: cover;
    min-height: 100vh;
}


.spacer{
  flex-grow: 1
}

.lora{
    font-family: 'Lora'
}


.mainRight {
    flex: 8;
    border-left: 1px solid black;
}

.black{
  color: black;
}
.headerText {
    font-size: 45px;
    font-weight: 600;
    font-family: 'Vollkorn'
}

.window {
    margin-left: 50px;
    margin-top: 50px;
}

.submitButton {
    display: flex;
    align-items: center;
    margin-left: 40vw;
}

.topRow {
    display: flex;
    flex-direction: row;
}
